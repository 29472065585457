<template>
  <div class="myhome">
    <div class="header">
      <van-cell-group :border="false">
      <van-cell :border="false" center is-link>
        <template #icon>
          <van-image
            width="60"
            height="60"
            lazy-load
            :src="info.logo"
            fit="cover"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #title>
          <div class="shop_name">
            <div class="van-ellipsis">{{info.merchname}}</div>
          </div>
          <div class="shop_data">
            店铺码：{{info.bianhao}}
          </div>
        </template>
        <template #default>
          <div class="right_list" @click="shopinfo">
            设置
          </div>
        </template>
      </van-cell>
      </van-cell-group>
      <van-cell-group :border="false">
        <van-cell center is-link @click="shopIndex">
          <template #icon>
            <van-icon name="wap-home-o" size="24" color="#0075C2" />
          </template>
          <template #title>
            店铺主页
          </template>
        </van-cell>
        <van-cell center is-link @click="shareshop">
          <template #icon>
            <van-icon name="qr" size="24" color="#0075C2" />
          </template>
          <template #title>
            店铺二维码
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="list_main">
      <van-cell-group :border="false">
        <van-cell title="店铺资料" @click="shopinfo" is-link center icon="description" />
        <van-cell v-if="false" title="主体信息" is-link center icon="idcard" />
        <van-cell v-if="false" title="交易设置" is-link center icon="balance-pay" />
        <van-cell v-if="false" title="经营资质" is-link center icon="sign" />
        <van-cell v-if="false" title="店铺装修" is-link center icon="shop-collect-o" />
        <van-cell v-if="false" title="子账户管理" is-link center icon="user-o" />
        <van-cell v-if="false" title="小程序" is-link center icon="discount" />
        <van-cell v-if="false" title="绑定公众号" is-link center icon="home-o" />
      </van-cell-group>
      <van-cell-group>
        <van-cell title="招商客服联系方式" @click="zhaoshang" is-link center icon="user-circle-o" />
      </van-cell-group>
    </div>
    <div class="logout">
      <van-button @click="logout" block type="danger" round>退出登录</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Myhome",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      xcx: 0
    }
  },
  mounted() {
    // this.init();
    console.log('ssss');
  },
  activated() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
    if (this.xcx == 0) {
      this.xcx = localStorage.getItem('xcx');
    }
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            console.log(that.info);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 店铺首页
    shopIndex() {
      if (this.xcx == 0) {
        window.location.href="https://dk.midbest.cn/?shareid=" + this.info.memberid + "&merchid=" + this.info.id
      }else {
        window.wx.miniProgram.switchTab({
          url: "/pages/index/index"
        })
      }
      
    },
    // 店铺二维码
    shareshop() {
      this.$router.push({
        name: "Shareshop",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 店铺资料
    shopinfo() {
      this.$router.push({
        name: "Shopinfo",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 退出登录
    logout() {
      localStorage.setItem("SHOP_LOGIN", false);
      localStorage.removeItem("SHOP_UID");
      this.$router.replace({
        name: "Login",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 招商联系方式
    zhaoshang() {
      if (this.xcx == 0) {
        window.location.href = "https://dk.midbest.cn/cooperation?shareid=" + this.info.memberid + "&merchid=" + this.info.id;
      }else {
        window.wx.miniProgram.navigateTo({
          url: "/pages2/cooperation/cooperation"
        })
      }
      
    }
  },
}
</script>
<style lang="less">
.myhome {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  // 头部
  .header {
    background: #f0f0f0;
    overflow: hidden;
    .van-cell {
      color: #333;
      background:  #fff;
      .van-image {
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid #ccc;
      }
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .shop_name {
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .shop_data {
          font-size: 12px;
          color: #333;
          display: flex;
          align-items: center;
          overflow: hidden;
          .shop_data_level {
            padding: 2px 5px;
            overflow: hidden;
            border-radius: 30px;
            margin-right: 5px;
            line-height: 10px;
            width: 65px;
            display: flex;
            align-items: center;
          }
        }
        .van-notice-bar {
          height: 24px;
          border-radius: 30px;
          font-size: 12px;
        }
      }
      .van-cell__value,
      .van-cell__right-icon {
        color: #333;
      }
    }
    .van-cell-group {
      background: #fff;
      margin: 10px 16px 0;
      overflow: hidden;
      border-radius: 6px;
      .van-cell {
        background: #fff;
        color: #333;
        .van-cell__title {
          padding: 0 10px;
        }
        .van-cell__right-icon {
          color: #333;
        }
      }
    }
  }
  // 按钮列表
  .list_main {
    overflow: hidden;
    .van-cell-group {
      margin: 10px 16px;
      overflow: hidden;
      border-radius: 6px;
      .van-cell {
        line-height: 30px;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-cell__left-icon {
          font-size: 24px;
          color: #E83D31;
        }
        .van-cell__left-icon, .van-cell__right-icon {
          height: 30px;
          line-height: 30px;
        }
      }
      &:nth-of-type(2) {
        .van-cell {
          line-height: 30px;
          .van-cell__title {
            flex: 1;
            text-align: left;
          }
          .van-cell__left-icon {
            font-size: 24px;
            color: #44B659;
          }
        }
      }
    }
  }
  .logout {
    margin: 20px 16px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
  }
}
</style>